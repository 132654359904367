<template>
  <v-row>
    <v-col cols="8">
      <CyEventsTimeline
        v-bind="{ events }"
        :options.sync="options"
        :show-tags="false">
        <template #actions="{ event: { notification } }">
          <v-list
            class="actions-menu"
            dense>
            <v-list-item
              class="px-4"
              @click="updateReadStatus(notification.id, notification.read_at ? false : true)">
              <v-list-item-icon class="mr-3">
                <v-icon color="primary">
                  {{ notification.read_at ? 'mark_chat_unread' : 'mark_chat_read' }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                {{ notification.read_at ? $t('markAsUnread') : $t('markAsRead') }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              class="px-4"
              @click="deleteNotification(notification.id)">
              <v-list-item-icon class="mr-3">
                <v-icon color="primary">
                  delete
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                {{ $t('forms.btnDelete') }}
              </v-list-item-content>
            </v-list-item>
            <div v-if="getWatchRule(notification.watch_rule_canonical)">
              <v-divider/>
              <v-list-item class="px-4">
                <v-list-item-content>
                  <span class="matching-watch-rules__title">
                    {{ $t('matchingWatchRules') }}
                  </span>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                class="px-4"
                @click="$router.push({ name: 'userWatchRule', params: { watchRuleCanonical: notification.watch_rule_canonical } })">
                <v-list-item-icon class="mr-3">
                  <CyAvatar
                    :item="{
                      icon: getWatchRule(notification.watch_rule_canonical).project_canonical ? 'commit' : 'podcasts',
                      color: getWatchRule(notification.watch_rule_canonical).project_canonical ? 'prod' : 'staging',
                    }"
                    sm/>
                </v-list-item-icon>
                <v-list-item-content>
                  {{ getWatchRule(notification.watch_rule_canonical).name }}
                  <CyTag
                    v-if="getWatchRule(notification.watch_rule_canonical).muted"
                    variant="default"
                    icon-before="volume_off">
                    {{ $t('muted') }}
                  </CyTag>
                </v-list-item-content>
              </v-list-item>
            </div>
          </v-list>
        </template>
      </CyEventsTimeline>
      <CyDataTablePagination
        v-if="notifications.length"
        class="v-data-footer py-1"
        :items-per-page-options="[25, 50, 100]"
        :items-length="notifications.length"
        :options.sync="options"/>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import CyDataTablePagination from '@/components/data-table/pagination.vue'
import CyEventsTimeline from '@/components/events/timeline.vue'
import { constructBreadcrumb } from '@/utils/helpers'

export default {
  name: 'CyPageNotifications',
  components: {
    CyEventsTimeline,
    CyDataTablePagination,
  },
  breadcrumb () {
    return constructBreadcrumb(this.$options.name, this.$t('routes.notifications'), [])
  },
  header () {
    return {
      title: this.$t('routes.notifications'),
    }
  },
  computed: {
    ...mapState('notifications', [
      'notifications',
      'watchRules',
    ]),
    ...mapGetters('layout', [
      'getDataTableProps',
    ]),
    events () {
      const events = this.notifications.map((notification) => ({
        notification: _.omit(notification, 'event'),
        ...notification.event,
        timestamp: notification.created_at * 1000,
      }))
      const sortedEvents = _.sortBy(events, ['timestamp']).reverse()
      return sortedEvents.slice(
        (this.options.page - 1) * this.options.itemsPerPage,
        this.options.page * this.options.itemsPerPage,
      )
    },
    options: {
      get () {
        return this.getDataTableProps(this.$route.name)
      },
      set (props) {
        this.SET_DATA_TABLE_PROPS({ name: this.$route.name, props })
      },
    },
  },
  created () {
    this.SET_DATA_TABLE_PROPS({
      name: this.$route.name,
      props: {
        ...this.getDataTableProps(this.$route.name),
      },
    })
  },
  mounted () {
    this.GET_NOTIFICATIONS()
    this.GET_WATCH_RULES()
  },
  methods: {
    ...mapActions('notifications', [
      'GET_NOTIFICATIONS',
      'GET_WATCH_RULES',
      'DELETE_NOTIFICATION',
      'MARK_NOTIFICATION_AS_READ',
      'MARK_NOTIFICATION_AS_UNREAD',
    ]),
    ...mapMutations('layout', [
      'SET_DATA_TABLE_PROPS',
    ]),
    getWatchRule (canonical) {
      return _.find(this.watchRules, ['canonical', canonical]) || null
    },
    async deleteNotification (notificationId) {
      await this.DELETE_NOTIFICATION({ notificationId })
      this.GET_NOTIFICATIONS()
    },
    async updateReadStatus (notificationId, read) {
      if (read) {
        await this.MARK_NOTIFICATION_AS_READ({ notificationId })
      } else {
        await this.MARK_NOTIFICATION_AS_UNREAD({ notificationId })
      }
      this.GET_NOTIFICATIONS()
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.notifications',
        markAsRead: 'Mark as read',
        markAsUnread: 'Mark as unread',
        matchingWatchRules: 'Matching watch rules',
      },
      es: {
        title: '@:routes.notifications',
        markAsRead: 'Marcar como leído',
        markAsUnread: 'Marcar como no leído',
        matchingWatchRules: 'Reglas de notificación coincidentes',
      },
      fr: {
        title: '@:routes.notifications',
        markAsRead: 'Marquer comme lu',
        markAsUnread: 'Marquer comme non lu',
        matchingWatchRules: 'Règles de notification correspondantes',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.actions-menu {
  color: cy-get-color("primary");

  .matching-watch-rules__title {
    color: cy-get-color("grey", "dark-2");
    font-size: $font-size-sm;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
  }
}

::v-deep .v-timeline-item {
  .v-timeline-item__opposite {
    background-color: cy-get-color("grey", "light-4");
  }
}
</style>
